  <!-- join community  -->
 <!-- about us  -->
<div class="sec-padding better_way_sec" *ngIf="router?.url.includes('en/about-us')">
  <div class="container z-index">
      <div class="row mx-0 align-items-center">
          <div class="col-md-6 col-sm-12">
              <h2 class="heading-one mx-auto mw-400 text-white m-ml-0">{{"aboutUs.joinGlobalCommunity.title" | translate}} <br>{{"aboutUs.joinGlobalCommunity.title2" | translate}}</h2>
          </div>
          <div class="col-md-6 col-sm-12">
              <div class="sec-title">
                  <p class="paragraph-global-text text-white mb-5 mw-400 sub-head-paragraph">{{"aboutUs.joinGlobalCommunity.description" | translate}}</p>
              </div>
              <div class="d-flex">
                  <!-- <a class="showMore-btn-cancel beter-btn" [href]="signUrl+'get-started/u/setup-profile?returnUrl=amy'" target="_blank">
                      {{"collaboration.heroSection.buttons.getStarted" | translate}}
                  </a> -->
                  <div class="join-subscriber-rgt w-100">
                      <div class="join-subscriber-rgt-in d-flex align-items-center">
                        <form [formGroup]="emailValidations" (ngSubmit)="onSubmit()" class="w-100 mw-400">
                          <input type="text" class="form-control registration-input" placeholder="{{'placeHolders.enetrmailid' | translate}}" formControlName="emailId" required>                        
                        </form>
                        <button type="submit" (click)="onSubmit()" class="save-btn ml-3">{{"HomePage.subscribe" | translate}}</button>
                        
                      </div>
                      <mat-error  *ngIf="f.emailId.touched  && f.emailId.errors?.required">Email is required</mat-error>
                      <mat-error  *ngIf="f.emailId.touched && f.emailId.errors?.email">Enter a valid email address</mat-error>
                      <!-- <mat-checkbox class="subscribe-mobile">{{"HomePage.SubscribeAumnics" | translate}}</mat-checkbox> -->
                      
                    </div> 
              </div>
          </div>
      </div>
  </div>        
</div>

<!-- service provider  -->
<div class="sec-padding better_way_sec" *ngIf="router?.url.includes('en/find-answers') || router?.url.includes('en/automate-tasks') || router?.url.includes('en/hire-contract-talent')">
  <div class="container z-index">
      <div class="row mx-0 align-items-center">
          <div class="col-md-6 col-sm-12">
              <h2 class="heading-one mx-auto mw-400 text-white">{{"amyServiceProvider.cta.title" | translate}} <br>{{"amyServiceProvider.cta.title2" | translate}}</h2>
          </div>
          <div class="col-md-6 col-sm-12">
              <div class="sec-title">
                  <p class="paragraph-global-text text-white mb-5 mw-450 sub-head-paragraph">{{"amyServiceProvider.cta.description" | translate}}</p>
              </div>
              <div class="d-flex">
                  <!-- <a class="showMore-btn-cancel beter-btn" [href]="signUrl+'get-started/u/setup-profile?returnUrl=amy'" target="_blank">
                      {{"collaboration.heroSection.buttons.getStarted" | translate}}
                  </a> -->
                  <div class="join-subscriber-rgt w-100">
                      <div class="join-subscriber-rgt-in d-flex align-items-center">
                        <form [formGroup]="emailValidations" (ngSubmit)="onSubmit()" class="w-100 mw-400">
                          <input type="text" class="form-control registration-input" placeholder="{{'placeHolders.enetrmailid' | translate}}" formControlName="emailId" required>                        
                        </form>
                        <button type="submit" (click)="onSubmit()" class="save-btn ml-3">{{"HomePage.subscribe" | translate}}</button>
                        
                      </div>
                      <mat-error  *ngIf="f.emailId.touched  && f.emailId.errors?.required">Email is required</mat-error>
                      <mat-error  *ngIf="f.emailId.touched && f.emailId.errors?.email">Enter a valid email address</mat-error>
                      <!-- <mat-checkbox class="subscribe-mobile">{{"HomePage.SubscribeAumnics" | translate}}</mat-checkbox> -->
                      
                    </div> 
              </div>
          </div>
      </div>
  </div>        
</div>
  
  <footer class="white-footer">
    <div class="container">
      <div class="row mx-0">
        <div class="col-md-3 col-sm-12 pl-0">
          <ul>
            <li class="d-flex flex-column">
              <a class="footer-logo" >
                <img height="40" loading="lazy" src="{{companyLogo}}" alt="footer-logo"> 
              </a>
              <p class="mt-2 paragraph-global-text footerCompanyAbout text-white" [ngClass]="{'max-200px': footerDescription === 'Cross-company collaboration made seamless.'}">
                {{footerDescription}}
              </p>
              <div class="social-icons mt-3 text-white">
                <ul class="no-flex-wrap">
                  <li>
                    <a href="{{sharedService.twitter}}" target="_blank">
                      <i class="fa-brands fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="{{sharedService.youtube}}" target="_blank">
                      <i class="fa-brands fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="{{sharedService.facebook}}" target="_blank">
                      <i class="fa-brands fa fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="{{sharedService.instagram}}" target="_blank">
                      <i class="fa-brands fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="{{sharedService.linkedin}}" target="_blank">
                      <i class="fa-brands fa fa-linkedin"></i></a>
                  </li>
          
                  <li>
                    <a href="{{sharedService.medium}}" target="_blank"><i class="fa-brands fa fa-medium"></i></a>
                  </li>
          
                </ul>
            </div>
            <!-- <div class="my-5 py-3">
              <a class="showMore-btn section-btn m-mb" [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName" target="_blank">{{"Header.getStarted" | translate}}</a>
            </div> -->
            <div class="copy-sec show-on-mobile p-0">
            </div>
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-sm-12 px-0">
          <ul class="row mx-0 w-100 footer-listing-parent">
            <li class="col-lg-6 col-sm-6 col-xs-12">
              <div class="footer-cont">
                <ul>
                  <li>
                    <h2 class="heading-three">{{"Footer.Company" | translate}}</h2>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'about-us']">{{"Footer.About" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'amy-service-provider']">{{"Footer.amyServiceProvider" | translate}}</a>
                  </li>
                  <li>
                    <a [href]="web_url + 'en/blogs'">{{"Footer.Blog" | translate}}</a>
                  </li>
                  <li>
                    <a [href]="web_url + 'en/faq'">{{"Footer.faq" | translate}}</a>
                  </li>
                  <li>
                    <a [href]="web_url + 'en/newsandmedia'">{{"Footer.Newsroom" | translate}}</a>
                  </li>
                  <li>
                      <a [href]="web_url + 'en/careers'">{{"Footer.Careers" | translate}}</a>
                  </li>                
                  <li>
                    <a [routerLink]="['/', languageCode, 'contact-us']">{{"Footer.ContactUs" | translate}}</a>
                  </li>
                </ul>
              </div>
            </li>
            
            <li class="col-lg-6 col-sm-6 col-xs-12">
              <div class="footer-cont">
                <ul>
                  <li>
                    <h2 class="heading-three">{{"Footer.Features" | translate}}</h2>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'ask-amy']">{{"Footer.AskAmy" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'enterprise-collaboration']">{{"Footer.EnterpriseCollaboration" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'project-management']">{{"Footer.ProjectManagement" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'integrated-marketplace']">{{"Footer.IntegratedMarketplaceServices" | translate}}</a>
                  </li>
                </ul>
              </div>
            </li>
            
            <!---community-->
            <li class="col-lg-6 col-sm-6 col-xs-12">
              <div class="footer-cont">
                <ul>
                  <li>
                    <h2 class="heading-three">{{"Footer.Community" | translate}}</h2>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'senior-proffesionals']">{{"Footer.seniorProfesionals" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'trusted-service-provider-partners']">{{"Footer.trustedPartners" | translate}}</a>
                  </li>                  
                  <li>
                    <a [routerLink]="['/', languageCode, 'developers']">{{"Footer.Developers" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'partners']">{{"Footer.Partners" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'affiliates-programme']">{{"Footer.AffiliatesProgramme" | translate}}</a>
                  </li>
                </ul>
              </div>
            </li>
            
            <!---solution-->
            <li class="col-lg-6 col-sm-6 col-xs-12">
              <div class="footer-cont">
                <ul>
                  <li>
                    <h2 class="heading-three">{{"Footer.Solutions" | translate}}</h2>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'pay-for-performance']">{{"Footer.PayForPerformance" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'all-in-one-app']">{{"Footer.AllInOneApp" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'cost-compare']">{{"Footer.CostCompareFeature" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'enterprise-ai']">{{"Footer.EnterpriseAI" | translate}}</a>
                  </li>                 
                </ul>
              </div>
            </li>                     
          </ul>
        </div>
        <div class="col-md-3 col-sm-12 px-0">
          <ul class="row mx-0 w-100 footer-listing-parent">
            <!---Industies-->
            <li class="col-lg-12 col-sm-12 col-xs-12 mb-0">
              <div class="footer-cont">
                <ul>
                  <li>
                    <h2 class="heading-three">{{"Footer.TopIndustries" | translate}}</h2>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'industries', 'Software-and-IT-Services']">{{"Footer.software.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'industries','Civil-Architecture-and-Planning']">{{"Footer.architecture.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'industries','Healthcare-Services']">{{"Footer.healthcare.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'industries','Design']">{{"Footer.design.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'digital-marketing']">{{"Footer.digital_marketing.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'writing-and-editing']">{{"Footer.writing.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'media-production']">{{"Footer.media.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'music-audio-and-voice']">{{"Footer.music.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'consulting-and-professional-services']">{{"Footer.consulting.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'legal-and-compliance-services']">{{"Footer.legal.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'logistics-and-supply-chain']">{{"Footer.logistics.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'insurance']">{{"Footer.insurance.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'environmental-services']">{{"Footer.environmental.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'research-and-analysis']">{{"Footer.research.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'events-services']">{{"Footer.events.title" | translate}}</a>
                  </li>
                  <li>
                    <a [routerLink]="['/', languageCode, 'training-and-educational-services']">{{"Footer.training.title" | translate}}</a>
                  </li>
                </ul>
              </div>
            </li> 
          </ul>
        </div>
      </div>
    </div>    
  </footer>

  <div class="copy white-footer">
    <div class="container">
      <hr class="hide-on-mobile border-gray">
        <div class="copy_in py-3">
            <div class="copy-sec border-none mb-3">
              <ul>
                <li class="remove-copy-link">
                  <a class="paragraph-global-text" [routerLink]="router.url"> &#169; {{"Footer.CopyRight" | translate}}</a>
                </li>
              </ul>
            </div>              
            <div class="copy-sec">
              <ul> 
                <li>
                  <a class="paragraph-global-text" [routerLink]="['/', languageCode, 'user-agreement']" [queryParams]="{ agreementType: appName }">{{"Footer.UserAgreement" | translate}}</a>
                </li>                 
                <li>
                  <a class="paragraph-global-text" [routerLink]="['/', languageCode, 'privacy-policy']" [queryParams]="{ policyType: appName }">{{"Footer.PrivacyPolicy" | translate}}</a>
                </li>
                <!-- <li>
                  <a [routerLink]="['/', languageCode, 'responsible-use-policy']" [queryParams]="{ agreementType: appName }">{{"Footer.responsible-use-policy" | translate}}</a>
                </li> -->
                <li>
                  <a class="paragraph-global-text" [routerLink]="['/', languageCode, 'responsible-use-policy']" [queryParams]="{ agreementType: appName }">Legal</a>
                </li>
                <li>
                  <a class="paragraph-global-text" [routerLink]="['/', languageCode, 'responsible-use-policy']" [queryParams]="{ agreementType: appName }">Site map</a>
                </li>
              </ul>
            </div>
        </div>
    </div>
</div>
  <p-toast></p-toast>