import { NgModule } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
// import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CaptchaModule } from 'primeng/captcha';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { ChipModule } from 'primeng/chip';
import { SkeletonModule } from "primeng/skeleton";
import {AccordionModule} from 'primeng/accordion';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';

const PrimeNgComponents: any[] = [
  AutoCompleteModule,
  // ButtonModule,
  CalendarModule,
  CaptchaModule,
  DropdownModule,
  InputTextModule,
  InputTextareaModule,
  InputNumberModule,
  MultiSelectModule,
  RadioButtonModule,
  ToastModule,
  ChipModule,
  AccordionModule,
  SkeletonModule,
  OverlayPanelModule,
  CheckboxModule,
  AvatarModule,
  AvatarGroupModule,
  BadgeModule
]

@NgModule({
  imports: [
    PrimeNgComponents
  ],
  exports: [
    PrimeNgComponents
  ]
})

export class PrimengModule { }
