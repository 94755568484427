<!-- <div class="join-us hide-on-tab" *ngIf="homeBlog && showRegularHeader">
  <p><span>{{"HomePage.new" | translate }}</span><a href="{{websiteUrl}}en/cms/blogs/{{homeBlog?.blogSlugName}}">
    {{ homeBlog?.blogTitle.length > 60 ? homeBlog?.blogTitle.substr(0, 60) + '...' : homeBlog?.blogTitle }} &nbsp;
    {{"HomePage.readMore" | translate}} <i class="fa-sharp fa fa-solid fa-angle-right"></i></a></p>    
</div> -->

<!-- Header for Amy/cms/clockit pages-->
<header class="header new-header-style" #header id="header" [ngClass]="{'headerColor' : (headerColor || expandedOptions), 'white-links-header' : (whiteLinks && !headerColor && !expandedOptions), 'expanded-options' : expandedOptions}">
      <div class="container-nav nav-bar-padding new-header">
        <div class="header-nav">
          <div class="logo web-logo" >
            <a [routerLink]="logoUrl" (click)="closeClick()" id="logoElement"><img loading="lazy"  height="40" [src]="(whiteLinks && !headerColor && !expandedOptions) ? '../../assets/images/home/Amy-logo-white.png' : '../../assets/icons/Homepage/amy-logo.png'" alt="amy-logo" [ngClass]="{'scaledUpLogo' : logoUrl !== '/en'}"></a>
          </div>
          <nav class="navbar navbar-expand-md navbar-dark">
            <button class="navbar-toggler menu-toggle" id="menu-toggle" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                class="title-color" [ngClass]="{'text-white' : (whiteLinks && !headerColor && !expandedOptions)}">
                <line x1="4" y1="6" x2="20" y2="6"/>
                <line x1="4" y1="12" x2="14" y2="12"/>
                <line x1="4" y1="18" x2="18" y2="18"/>
              </svg>
              <!-- <mat-icon> menu </mat-icon> -->
            </button>
            <div class="navbar-collapse">
              
              <div class="mobile-humberge">
                <a [routerLink]="logoUrl" (click)="closeClick()" class="mobileHeaderLogo" id="mobileHeaderLogo"><img loading="lazy" height="40" [src]="'../../assets/icons/Homepage/amy-logo.png'" alt="amy-logo"></a>
                <span class="backText primary-color f-16 d-none" id="backText" (click)="recetAllMobileElements()"> <i class="pi pi-arrow-left primary-color mr-2" style="font-size: 13px;"></i> Back</span>
                <mat-icon class="navbar-toggler1" id="navbar-toggler1" (click)="recetAllMobileElements()"> close </mat-icon>
              </div>
              <span></span>
  
              <ul class="navbar-nav search-nav-sec show-on-mobile">
                <!-- mobile nav links  -->
                <li class="nav-item show-on-mobile" id="businessEcosystemMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap ">{{"Header.newBusinessEcosystem" | translate}} </a>
                    <span class="mt-1"><i class="pi pi-chevron-right ml-1"></i></span>
                  </div>     
                  <ng-container *ngTemplateOutlet="businessMobileOptions"></ng-container>        
                </li>  
                <li class="nav-item show-on-mobile" id="wantToAmyMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap " [routerLink]="['/', languageCode, 'amy-service-provider']"
                    (click)="recetAllMobileElements()">{{"Header.wantToAmyAgency" | translate}} </a>
                  </div>      
                </li>  
                <li class="nav-item show-on-mobile" id="industriesMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap " >{{"Header.topIndustries" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-chevron-right ml-1"></i></span>
                  </div>      
                  <ng-container *ngTemplateOutlet="indutryMobileOptions"></ng-container>              
                </li>
                <li class="nav-item show-on-mobile" id="pricingMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap " [routerLink]="['/', languageCode, 'pricing']" routerLinkActive="active"
                    (click)="recetAllMobileElements()">{{"Header.plansAndPricing" | translate}}</a>
                  </div>    
                </li>
                <li class="nav-item show-on-mobile" id="compareMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap " [routerLink]="['/', languageCode, 'compare']" routerLinkActive="active"
                    (click)="recetAllMobileElements()">{{"Header.compare" | translate}} </a>
                  </div>      
                </li>
                <li class="nav-item show-on-mobile" id="learnMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap " >{{"Header.learn" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-chevron-right ml-1"></i></span>
                  </div>  
                  <ng-container *ngTemplateOutlet="learnMobileOptions"></ng-container>                  
                </li>
                <li class="mobile-padding-div"></li>
              </ul>
  
                 <!-- Web nav links  -->
              <div class="humberge-menu-section w-100">
              <ul class="navbar-nav signup-last">
                <li class="nav-item hide-on-mobile" [ngClass]="{'active' : checkUrlIncluded(collaborationUrls)}" id="businessEcosystemElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " [routerLink]="router.url" >{{"Header.newBusinessEcosystem" | translate}} </a>
                    <span class="mt-1"><i class="pi pi-angle-down ml-1"></i></span>
                  </div>                  
                </li> 
                <li class="nav-item hide-on-mobile" id="wantToAmyElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " [routerLink]="['/', languageCode, 'amy-service-provider']" routerLinkActive="active">{{"Header.wantToAmyAgency" | translate}} </a>
                  </div>                  
                </li>  
                <li class="nav-item hide-on-mobile" [ngClass]="{'active' : checkUrlIncluded(industryUrls)}" id="industriesElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " >{{"Header.topIndustries" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-angle-down ml-1"></i></span>
                  </div>                  
                </li>
                <li class="nav-item hide-on-mobile" id="pricingElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " [routerLink]="['/', languageCode, 'pricing']" routerLinkActive="active">{{"Header.plansAndPricing" | translate}}</a>
                  </div>   
                </li>
                <li class="nav-item hide-on-mobile" id="compareElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " [routerLink]="['/', languageCode, 'compare']" routerLinkActive="active">{{"Header.compare" | translate}} </a>
                  </div>                  
                </li> 
                <li class="nav-item hide-on-mobile" id="learnElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " >{{"Header.learn" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-angle-down ml-1"></i></span>
                  </div>                  
                </li>
                <ng-container *ngIf="whiteLinks && !headerColor && !expandedOptions">
                  <!-- web view  -->
                  <li class="nav-item ml-auto hide-on-mobile">
                    <a class="nav-link dropbtn special-link text-wrap" [href]="signUrl+'sign-in?returnUrl='+appName" id="loginElement">
                      {{"Header.logIn" | translate}}
                    </a>
                  </li>
                  <li class="nav-item signup hide-on-mobile">
                    <a id="signupElement" [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName"> 
                      <button class="save-btn cfa"> {{"Header.getStarted" | translate}}</button> 
                    </a>
                  </li>

                  <!-- mobile view  -->
                  <li class="nav-item show-on-mobile">
                    <a class="cancel-btn" [href]="signUrl+'sign-in?returnUrl='+appName" id="loginElement">
                      {{"Header.logIn" | translate}}
                    </a>
                  </li>
                  <li class="nav-item signup show-on-mobile">
                    <a id="signupElement" [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName"> 
                      <button class="save-btn cfa"> {{"Header.getStarted" | translate}}</button> 
                    </a>
                  </li>
                </ng-container>
                <ng-container *ngIf="!(whiteLinks && !headerColor && !expandedOptions)">
                  <!-- web view -->
                  <li class="nav-item ml-auto hide-on-mobile">
                    <a class="nav-link dropbtn special-link text-wrap 1" [href]="signUrl+'sign-in?returnUrl='+appName" id="loginElement">
                      {{"Header.logIn" | translate}}
                    </a>
                  </li>
                  <li class="nav-item signup hide-on-mobile 2">
                    <a id="signupElement" [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName"> 
                      <button class="save-btn cfa"> {{"Header.getStarted" | translate}}</button> 
                    </a>
                  </li>

                  <!-- mobile view  -->
                  <li class="nav-item show-on-mobile 3">
                    <a class="cancel-btn" [href]="signUrl+'sign-in?returnUrl='+appName" id="loginElement">
                      {{"Header.logIn" | translate}}
                    </a>
                  </li>
                  <li class="nav-item signup show-on-mobile 4">
                    <a id="signupElement" [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName"> 
                      <button class="save-btn cfa"> {{"Header.getStarted" | translate}}</button> 
                    </a>
                  </li>
                </ng-container>                
              </ul>
  
               <div class="social-icons mobile-social-icons">
  
                <ul>
                  <li>
                    <a href="{{sharedService.twitter}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.youtube}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-youtube"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.facebook}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.instagram}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-instagram"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.linkedin}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.medium}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-medium"></i></a>
                  </li>
  
                </ul>
            </div>
          </div>
             
            </div>
          </nav>
  
        </div>
        <a class="mobile-sign-up save-btn cfa" [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName">{{'Header.getStarted' | translate}}</a>
      </div>  

      <!-- forms gray bg below options  -->
      <div class="header-overlay" id="bgOverlay" [ngClass]="{'show' : expandedOptions}"></div>
</header>

<!-- web menu options  -->

<div class="relativeDiv" id="relativeDiv">
  <ul class="c-extnav-level__1">
    <ng-container *ngIf="currentExpandedElement === 'business'">
         <!-- Business content here -->
      <div class="businessrelativeDiv" id="businessrelativeDiv">
        <ul class="businessOptionsParent">
          <div class="row mx-0">

            <div class="col-md-3">
              <div class="left-highlighted-section h-100">
                <div class="p-4 text-center">
                  <div class="mx-auto my-3 d-flex justify-content-center">
                    <figure>
                      <img src="../../assets/icons/nav-bar/client-logo.svg" alt="{{'expandBusiness.title' | translate}}">
                    </figure>
                  </div>
                  <div class="d-flex">
                    <h5 class="heading-three cfa-color">{{"expandBusiness.title" | translate}}</h5>
                  </div>
                  <p class="paragraph-global-text text-white pr-4">{{"expandBusiness.desc" | translate}}</p>
                  <!-- <p class="paragraph-global-text pr-4">{{"expandBusiness.descAfter" | translate}}</p> -->
                  <a [routerLink]="['/', languageCode, 'book-demo']" target="_blank" (click)="onClickWebElement('business')">
                    <button class="showMore-btn-cancel white-btn transparent-bg fw-500 w-100 mt-3">{{"expandBusiness.btnServiceProvider" | translate}}</button>
                  </a>
                  <a [routerLink]="['/', languageCode, 'find-agencies']" (click)="onClickWebElement('business')">
                    <button class="save-btn cfa fw-500 w-100 mt-4">{{"expandBusiness.btnStartNow" | translate}}</button>
                  </a>
                  
                </div>
              </div>
            </div>

            <div class="col-md-9">
              <div class="">
                <div class="row mx-0">
                  <div class="collab-item-1 h-100">
                    <div class="">
                      <div class="card-body border-bottom">
                        <h5 class="card-title mb-0">{{"askAmy.title" | translate}} {{"askAmy.title2" | translate}}</h5>
                      </div>
                      <div class="card-body">                        
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-answers'" (click)="router.url != '/en/find-answers' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"askAmy.items.getAnswers" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Get instant solutions to your queries</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/automate-tasks'" (click)="router.url != '/en/automate-tasks' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"askAmy.items.automateTasks" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Simplify and streamline your workflows</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/hire-contract-talent'" (click)="router.url != '/en/hire-contract-talent' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"askAmy.items.hireContractTalent" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Quickly connect with skilled professionals</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-insights'" (click)="router.url != '/en/find-insights' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"askAmy.items.findInsights" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Access actionable data and analytics</p>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="collab-item-2 h-100 border-left">
                    <div class="h-100">
                      <div class="card-body border-bottom">
                        <h5 class="card-title mb-0">{{"projectManagement.title" | translate}} {{"projectManagement.title2" | translate}}</h5>
                      </div>
                      <div class="card-body">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/plan-collaborately'" (click)="router.url != '/en/plan-collaborately' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"projectManagement.items.planCollaborately" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Work together to create effective project plans</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/set-goals-targets'" (click)="router.url != '/en/set-goals-targets' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"projectManagement.items.setGoals" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Define clear objectives and measure progress</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/surveys'" (click)="router.url != '/en/surveys' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"projectManagement.items.sprintSurveys" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Track performance with regular surveys</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/tasks-subtasks-bugs'" (click)="router.url != '/en/tasks-subtasks-bugs' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"projectManagement.items.tasksAndBugs" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Organize and manage project details effortlessly</p>
                            </a>
                          </li>
                          <!-- <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"projectManagement.items.mentionsAndComments" | translate}}</a>
                          </li> -->
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none"  [routerLink]=" '/' + languageCode + '/time-sheets'" (click)="router.url != '/en/time-sheets' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"projectManagement.items.timeSheets" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Track and log work hours seamlessly</p>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
        
                  <div class="collab-item-3 h-100 border-left">
                    <div class="h-100">
                      <div class="card-body border-bottom">
                        <h5 class="card-title mb-0">{{"marketplaceServices.title" | translate}} {{"marketplaceServices.title2" | translate}}</h5>
                      </div>
                      <div class="card-body">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/industry-wise-templates'" (click)="router.url != '/en/industry-wise-templates' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"marketplaceServices.items.industryTemplates" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Leverage ready-to-use templates for your domain</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/compare-prices-of-agencies'" (click)="router.url != '/en/compare-prices-of-agencies' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"marketplaceServices.items.comparePrices" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Evaluate costs and choose the best partners</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/experience-vertical-marketplace'" (click)="router.url != '/en/experience-vertical-marketplace' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"marketplaceServices.items.verticalMarketplace" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Access specialized tools tailored for your industry</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/manage-in-house-teams'" (click)="router.url != '/en/manage-in-house-teams' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"marketplaceServices.items.manageTeams" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Coordinate all teams in one place</p>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
        
                  <div class="collab-item-4 h-100 border-left">
                    <div >
                      <div class="card-body border-bottom">
                        <h5 class="card-title mb-0">{{"enterpriseCollaboration.title" | translate}} {{"enterpriseCollaboration.title2" | translate}}</h5>
                      </div>
                      <div class="card-body">                        
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/one-to-one-chat'" (click)="router.url != '/en/collaboration/one-to-one-chat' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"enterpriseCollaboration.items.oneToOneChat" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Communicate directly with team members</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/group-chat'" (click)="router.url != '/en/collaboration/group-chat' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"enterpriseCollaboration.items.groupChat" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Collaborate effectively in team discussions</p>
                            </a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/video-calls-sharing'" (click)="router.url != '/en/collaboration/video-calls-sharing' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"enterpriseCollaboration.items.videoCalls" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Conduct virtual meetings and share screens</p>
                            </a>
                          </li>
                          <!-- <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"enterpriseCollaboration.items.screenSharing" | translate}}</a>
                          </li> -->
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/file-sharing'" (click)="router.url != '/en/collaboration/file-sharing' ? recetWebNavElemets() : null;">
                              <h4 class="heading-four mb-1">{{"enterpriseCollaboration.items.fileSharing" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                              <p class="sub-heading-color f-12 mb-0">Exchange documents and files securely</p>
                            </a>
                          </li>
                          <!-- <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/calender-features'" (click)="router.url != '/en/find-answers' ? recetWebNavElemets() : null;">{{"enterpriseCollaboration.items.calendar" | translate}}</a>
                          </li> -->
                          <!-- <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"enterpriseCollaboration.items.invoices" | translate}}</a>
                          </li> -->
                        </ul>
                      </div>                
                    </div>                    
                  </div>  
                </div>
              </div>

              <!-- cms apps  -->
              <div class="border-top">
                <div class="card-body py-2">
                  <ul class="list-group list-group-flush d-flex" style="flex-direction: row;gap: 2rem;">
                    <li class="list-group-item paragraph-global-text mb-0 d-flex align-items-center">
                      <h5 class="card-title mb-0 mr-4">{{"appsAndIntegrations.title" | translate}} {{"appsAndIntegrations.title2" | translate}}</h5>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0">
                      <a class="text-decoration-none d-flex align-items-center" [routerLink]=" '/' + languageCode + '/apps/amy-cms'" (click)="router.url != '/en/apps/amy-cms' ? recetWebNavElemets() : null;">
                        <div class="opt-img" style="padding: 5px 5px 5px 0; margin-right: 5px;">
                          <img width="30" src="../../assets/icons/nav-bar/amy-CMS.svg" alt="{{'expandBusiness.title' | translate}}">
                        </div>
                        <h6 class="heading-four mb-0">{{"appsAndIntegrations.items.amyCMS" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i></h6>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0">
                      <a class="text-decoration-none d-flex align-items-center" [routerLink]=" '/' + languageCode + '/apps/gitlab'" (click)="router.url != '/en/apps/gitlab' ? recetWebNavElemets() : null;">
                        <div class="opt-img" style="padding: 5px 5px 5px 0; margin-right: 5px;">
                          <img width="30" src="https://aumnicsaiappdatastore.s3.amazonaws.com/app_logos/gitlab.svg" alt="{{'expandBusiness.title' | translate}}">
                        </div>
                        <h6 class="heading-four mb-0">{{"appsAndIntegrations.items.gitlab" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i></h6>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0">
                      <a class="text-decoration-none d-flex align-items-center" [routerLink]=" '/' + languageCode + '/apps/jenkins'" (click)="router.url != '/en/apps/jenkins' ? recetWebNavElemets() : null;">
                        <div class="opt-img" style="padding: 5px 5px 5px 0; margin-right: 5px;">
                          <img width="30" src="https://aumnicsaiappdatastore.s3.amazonaws.com/app_logos/jenkins.svg" alt="{{'expandBusiness.title' | translate}}">
                        </div>
                        <h6 class="heading-four mb-0">{{"appsAndIntegrations.items.Jenkins" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i></h6>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0">
                      <a class="text-decoration-none d-flex align-items-center" [routerLink]=" '/' + languageCode + '/apps/google-calendar'" (click)="router.url != '/apps/google-calendar' ? recetWebNavElemets() : null;">
                        <div class="opt-img" style="padding: 5px 5px 5px 0; margin-right: 5px;">
                          <img width="30" src="https://aumnicsaiappdatastore.s3.amazonaws.com/app_logos/calendar.svg" alt="{{'expandBusiness.title' | translate}}">
                        </div>
                        <h6 class="heading-four mb-0">{{"appsAndIntegrations.items.googleCalendar" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i></h6>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </ng-container>
    <ng-container *ngIf="currentExpandedElement === 'industry'">
         <!-- Industry content here -->
        <div class="indutryrelativeDiv w-100" id="indutryrelativeDiv">
          <ul class="industryOptionsParent w-100">
            <div class="row mx-0">            
              <div class="col-md-9">
                <div class="px-4 py-3 border-bottom">
                  <h5 class="card-title mb-0 ml-4">{{"topIndustries.title" | translate}} {{"topIndustries.title2" | translate}}</h5>
                </div>
                <div class="pl-4">
                  <div class="card-body">
                    <div class="w-100 d-flex">
                      <ul class="list-group list-group-flush industry-grid" *ngFor="let column of [0, 1, 2]">
                        <li class="list-group-item paragraph-global-text template-item mb-0 pr-3" *ngFor="let template of getColumnTemplates(column); let index = index;">
                          <a class="text-decoration-none" [routerLink]="(index < 8 && column < 1) ? template.url: router.url" (click)="router.url != ('/'+template.url) ? recetWebNavElemets() : null;">
                            <h4 class="heading-four mb-0"> <img class="industry-image mr-2" width="18" [src]="template.thumbnailImageUrl" [alt]="template.industryCategory"> {{template.industryCategory}} </h4>
                            <p class="sub-heading-color f-12 ml-4 pl-2" style="max-width: 270px;">{{template.shortDescription}}</p>
                          </a>
                        </li>
                      </ul>  
                    </div>                
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="rgt-highlighted-section h-100">
                  <div class="card-body d-flex p-4 flex-column">    
                    <div class="opt-img single">
                      <img src="../../assets/icons/nav-bar/get-started.svg" alt="{{'gettingStarted.title' | translate}}">
                    </div>        
                    <div>
                      <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
                        {{"gettingStarted.title" | translate}} {{"gettingStarted.title2" | translate}} 
                      </h5>
                      <p class="paragraph-global-text ">{{"gettingStarted.description" | translate}}</p>
                    </div>
                    <a class="text-decoration-none">
                      <h6 class="heading-four mt-5 mb-0">{{"gettingStarted.subTitle" | translate}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i></h6>
                    </a>                    
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
    </ng-container>
    <ng-container *ngIf="currentExpandedElement === 'learn'">
        <!-- Learn content here -->
        <div class="learnrelativeDiv" id="learnrelativeDiv">
          <ul class="learnOptionsParent">
            <div class="row mx-0"> 
              <div class="col-md-3">
                <div class="left-highlighted-section learn h-100">
                  <div class="p-4">
                    <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
                      {{"makingCollaborationEasy.title" | translate}} {{"makingCollaborationEasy.title2" | translate}} </h5>
                    <p class="paragraph-global-text " style="line-height: 1.3 !important;">{{"makingCollaborationEasy.description" | translate}}</p>
                    <div class="d-flex my-3">
                      <figure>
                        <img class="w-100" style="object-fit: cover;border-radius: 8px;" src="../../assets/icons/collaboration-features/copied-template.png" alt="{{'expandBusiness.title' | translate}}">
                      </figure>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item paragraph-global-text mb-0" *ngIf="digitalMarketingTemplate">
                        <a class="text-decoration-none" (click)="navigateToTemplate(digitalMarketingTemplate);recetWebNavElemets();">
                          <h4 class="heading-four mb-1">{{digitalMarketingTemplate.templateName}} 
                            <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                        </a>
                      </li>
                      <li class="list-group-item paragraph-global-text mb-0" *ngIf="designTemplate">
                        <a class="text-decoration-none" (click)="navigateToTemplate(designTemplate);recetWebNavElemets();">
                           <h4 class="heading-four mb-1">{{designTemplate.templateName}} <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> </h4>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="p-2 learn-grid">
                  <div class="">
                    <li class="list-group-item paragraph-global-text mb-0">                      
                      <a class="text-decoration-none px-2 pt-4 mb-4" [href]=" appsUrl + 'en/faq'" (click)="router.url != '/en/find-answers' ? recetWebNavElemets() : null;">
                        <div class="opt-img hover-blue-img mb-3">
                          <img src="../../assets/icons/nav-bar/help-center.svg" alt="{{'helpCentre.title' | translate}}">
                        </div>
                        <h4 class="heading-four mb-0">
                          {{"helpCentre.title" | translate}} {{"helpCentre.title2" | translate}} 
                          <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> 
                        </h4>
                        <p class="paragraph-global-text ">{{"helpCentre.description" | translate}}</p>
                      </a>
                    </li>
                  </div>
                  <div class="">
                    <li class="list-group-item paragraph-global-text mb-0">                      
                      <a class="text-decoration-none px-2 pt-4 mb-4" [href]=" appsUrl + 'en/blogs'" (click)="router.url != '/en/find-answers' ? recetWebNavElemets() : null;">
                        <div class="opt-img hover-blue-img mb-3">
                          <img src="../../assets/icons/nav-bar/amy-blog.svg" alt="{{'amyBlog.title' | translate}}">
                        </div>
                        <h4 class="heading-four mb-0">
                          {{"amyBlog.title" | translate}} {{"amyBlog.title2" | translate}}
                          <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> 
                        </h4>
                        <p class="paragraph-global-text ">{{"amyBlog.description" | translate}}</p>
                      </a>
                    </li>
                  </div>
                  <div class="">
                    <li class="list-group-item paragraph-global-text mb-0">                      
                      <a class="text-decoration-none px-2 pt-4 mb-4" [href]="signUrl+'sign-in?returnUrl='+appName + '&tutorials=true'" (click)="router.url != '/en/find-answers' ? recetWebNavElemets() : null;">
                        <div class="opt-img hover-blue-img mb-3">
                          <img src="../../assets/icons/nav-bar/collaboration-easy.svg" alt="{{'collaborationSchool.title' | translate}}">
                        </div>
                        <h4 class="heading-four mb-0">
                          {{"collaborationSchool.title" | translate}} {{"collaborationSchool.title2" | translate}}
                          <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> 
                        </h4>
                        <p class="paragraph-global-text ">{{"collaborationSchool.description" | translate}}</p>
                      </a>
                    </li>
                  </div>
                  <div>
                    <li class="list-group-item paragraph-global-text mb-0">                     
                      <a class="text-decoration-none px-2 pt-4 mb-4" [href]="commonService.youtubeChannelLink" target="_blank" (click)="router.url != '/en/find-answers' ? recetWebNavElemets() : null;">
                        <div class="opt-img hover-blue-img mb-3">
                          <img src="../../assets/icons/nav-bar/productivity2.svg" alt="{{'productivityRevolutionPodcast.title' | translate}}">
                        </div>
                        <h4 class="heading-four mb-0">
                          {{"productivityRevolutionPodcast.title" | translate}} {{"productivityRevolutionPodcast.title2" | translate}}
                          <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i> 
                        </h4>
                        <p class="paragraph-global-text ">{{"productivityRevolutionPodcast.description" | translate}}</p>
                      </a>
                    </li>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="rgt-highlighted-section learn h-100">          
                  <div class="p-4">    
                    <div class="opt-img single">
                      <img src="../../assets/icons/nav-bar/get-started.svg" alt="{{'gettingStarted.title' | translate}}">
                    </div>        
                    <div>
                      <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
                        {{"gettingStarted.title" | translate}} {{"gettingStarted.title2" | translate}} 
                      </h5>
                      <p class="paragraph-global-text ">{{"gettingStarted.description" | translate}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
    </ng-container>
  </ul>
</div>


<!-- mobile menu options  -->
<ng-template #businessMobileOptions>
  <ul class="c-m-extnav-level__1 p-4 businessMobileOptionsParent">
    <div class="">
      <div class="row mx-0">
        <div class="col-md-6 col-sm-12 px-0">
          <div class="d-flex align-items-start">
            <h5 class="card-title">{{"askAmy.title" | translate}} {{"askAmy.title2" | translate}}</h5>
          </div>
          <ul class="list-group list-group-flush mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-answers'" (click)="resetAndCloseMobileElements()">{{"askAmy.items.getAnswers" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/automate-tasks'" (click)="resetAndCloseMobileElements()">{{"askAmy.items.automateTasks" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/hire-contract-talent'" (click)="resetAndCloseMobileElements()">{{"askAmy.items.hireContractTalent" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-insights'" (click)="resetAndCloseMobileElements()">{{"askAmy.items.findInsights" | translate}}</a>
                    </li>
          </ul>
        </div>

        <div class="col-md-6 col-sm-12 px-0">
          <div class="d-flex align-items-start">
            <h5 class="card-title">{{"projectManagement.title" | translate}} {{"projectManagement.title2" | translate}}</h5>
          </div>
          <ul class="list-group list-group-flush mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/plan-collaborately'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.planCollaborately" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/set-goals-targets'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.setGoals" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/surveys'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.sprintSurveys" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/tasks-subtasks-bugs'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.tasksAndBugs" | translate}}</a>
            </li>
            <!-- <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"projectManagement.items.mentionsAndComments" | translate}}</a>
                    </li> -->
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/time-sheets'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.timeSheets" | translate}}</a>
            </li>
          </ul>
        </div>

        <div class="col-md-6 col-sm-12 px-0">
          <div class="d-flex align-items-start">
            <h5 class="card-title">{{"marketplaceServices.title" | translate}} {{"marketplaceServices.title2" | translate}}</h5>
          </div>
          <ul class="list-group list-group-flush mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/industry-wise-templates'" (click)="resetAndCloseMobileElements()">{{"marketplaceServices.items.industryTemplates" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/compare-prices-of-agencies'" (click)="resetAndCloseMobileElements()">{{"marketplaceServices.items.comparePrices" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/experience-vertical-marketplace'" (click)="resetAndCloseMobileElements()">{{"marketplaceServices.items.verticalMarketplace" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/manage-in-house-teams'" (click)="resetAndCloseMobileElements()">{{"marketplaceServices.items.manageTeams" | translate}}</a>
                    </li>
          </ul>          
        </div>


        <div class="col-md-6 col-sm-12 px-0">
          <div class="d-flex align-items-start">
            <h5 class="card-title">{{"enterpriseCollaboration.title" | translate}} {{"enterpriseCollaboration.title2" | translate}}</h5>
          </div>
          <ul class="list-group list-group-flush  mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/one-to-one-chat'" (click)="resetAndCloseMobileElements()">{{"enterpriseCollaboration.items.oneToOneChat" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/group-chat'" (click)="resetAndCloseMobileElements()">{{"enterpriseCollaboration.items.groupChat" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/video-calls-sharing'" (click)="resetAndCloseMobileElements()">{{"enterpriseCollaboration.items.videoCalls" | translate}}</a>
                    </li>
            <!-- <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"enterpriseCollaboration.items.screenSharing" | translate}}</a>
                    </li> -->
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/file-sharing'" (click)="resetAndCloseMobileElements()">{{"enterpriseCollaboration.items.fileSharing" | translate}}</a>
                    </li>
            <!-- <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/calender-features'" (click)="resetAndCloseMobileElements()">{{"enterpriseCollaboration.items.calendar" | translate}}</a>
                    </li> -->
            <!-- <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"enterpriseCollaboration.items.invoices" | translate}}</a>
                    </li> -->
          </ul>
          <h5 class="card-title">{{"appsAndIntegrations.title" | translate}} {{"appsAndIntegrations.title2" | translate}}</h5>
          <ul class="list-group list-group-flush mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/amy-cms'"
              (click)="resetAndCloseMobileElements()">{{"appsAndIntegrations.items.amyCMS" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/gitlab'"
                (click)="resetAndCloseMobileElements()">{{"appsAndIntegrations.items.gitlab" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/jenkins'"
                (click)="resetAndCloseMobileElements()">{{"appsAndIntegrations.items.Jenkins" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/google-calendar'"
                (click)="resetAndCloseMobileElements()">{{"appsAndIntegrations.items.googleCalendar" | translate}}</a>
            </li>
          </ul>
        </div>  
      </div>
    </div>
  </ul>
</ng-template>


<ng-template #indutryMobileOptions>
  <ul class="c-m-extnav-level__1 p-4 industryMobileOptionsParent">
    <div class="row mx-0">     
      <div class="col-sm-12 col-md-6 px-0 mb-4">
        <div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none"  [routerLink]=" '/' + languageCode + '/industries/software-and-IT-service'" (click)="recetAllMobileElements();">{{"topIndustries.industriesList.softwareAndITService" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.design" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.digitalMarketing" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.writingEditing" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.mediaProduction" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.musicAudioVoice" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.corporateServices" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.marketingSalesConsulting" | translate}}</a>
                    </li>
                    <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.professionalTrainingCoaching" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.managementConsulting" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.trainingEducationalServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.researchAnalysis" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.eventsServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.architecturePlanning" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.fashionLifestyleServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.logisticsSupplyChain" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.environmentalServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.healthcareServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.intellectualPropertyLawServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.insurance" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0"  [routerLink]=" '/' + languageCode + '/industries/real-estate'" (click)="recetAllMobileElements();">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.realEstateServices" | translate}}</a>
                    </li>
          </ul>
        </div>
      </div>

      <div class="col-12 px-0">
        <div class="mb-4">
          <div class="w-100">
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center justify-content-between">
              <div>{{"amyGold.title" | translate}} {{"askAmy.title2" | translate}}</div> 
              <i class="pi pi-chevron-right"></i> 
            </h5>
            <p class="paragraph-global-text mobile-description">{{"amyGold.description" | translate}}</p>
          </div>
        </div>
        <div class="mb-4">
          <div class="w-100">
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center justify-content-between">
              <div>{{"amyEnterprise.title" | translate}} {{"amyEnterprise.title2" | translate}}</div> 
              <i class="pi pi-chevron-right"></i>
            </h5>
            <p class="paragraph-global-text mobile-description">{{"amyEnterprise.description" | translate}}</p>
          </div>
        </div>
        <div>
          <div class="w-100">
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center justify-content-between">
              <div>{{"contactSales.title" | translate}} {{"contactSales.title2" | translate}}</div> 
               <i class="pi pi-chevron-right"></i>
            </h5>
            <p class="paragraph-global-text mobile-description">{{"contactSales.description" | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </ul>
</ng-template>

<ng-template #learnMobileOptions>
  <ul class="c-m-extnav-level__1 p-4 learnMobileOptionsParent">
    <div class="row mx-0"> 
      <div class="col-md-4 px-0">
        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"makingCollaborationEasy.title" | translate}} {{"makingCollaborationEasy.title2" | translate}} </h5>
            <p class="paragraph-global-text  mobile-description">{{"makingCollaborationEasy.description" | translate}}</p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"makingCollaborationEasy.options.travelAgents" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"makingCollaborationEasy.options.digitalMarketingAgencies" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"makingCollaborationEasy.options.graphicDesignAgencies" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"makingCollaborationEasy.options.createCustomProjectTemplates" | translate}}</a>
                    </li>
            </ul>
          </div>
        </div>

        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"helpCentre.title" | translate}} {{"helpCentre.title2" | translate}} </h5>
            <p class="paragraph-global-text  mobile-description">{{"helpCentre.description" | translate}}</p>
          </div>
        </div>

        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"amyBlog.title" | translate}} {{"amyBlog.title2" | translate}} </h5>
            <p class="paragraph-global-text  mobile-description">{{"amyBlog.description" | translate}}</p>
          </div>
        </div>
        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"collaborationSchool.title" | translate}} {{"collaborationSchool.title2" | translate}}</h5>
            <p class="paragraph-global-text mobile-description">{{"collaborationSchool.description" | translate}}</p>
          </div>
        </div>
        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"productivityRevolutionPodcast.title" | translate}} {{"productivityRevolutionPodcast.title2" | translate}}
            </h5>
            <p class="paragraph-global-text mobile-description">{{"productivityRevolutionPodcast.description" | translate}}</p>
          </div>
        </div> 
        <div class="">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"gettingStarted.title" | translate}}  {{"gettingStarted.title2" | translate}}</h5>
            <p class="paragraph-global-text mobile-description">{{"gettingStarted.description" | translate}}</p>
          </div>
        </div>      
      </div>  
    </div>
  </ul>
</ng-template>
